<template>
  <div class="d-flex flex-column flex-lg-row">
    <!--begin::Sidebar-->
    <div class="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
      <!--begin::Card-->
      <div class="card card-flush h-md-100">
        <!--begin::Card header-->
        <div class="card-header">
          <div class="row d-flex justify-content-between">
            <div class="col-md-10">
              <div class="card-title mt-8">
                <router-link to="/interview/jobposting-detail">
                  <h2 class="mb-0 text-hover-primary">
                    Hybrid Çalışan Frontend Developer
                  </h2>
                </router-link>
              </div>
            </div>
            <div class="col-md-2 d-flex justify-content-end align-items-center">
              <span
                class="
                  svg-icon svg-icon-2
                  btn btn-sm btn-bg-light btn-active-color-primary
                "
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_update_role"
              >
                <inline-svg src="/media/icons/duotune/art/art005.svg" />
              </span>
            </div>
          </div>
          
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-1">
          <p><b>Tarih: 08.11.2024 - 01.01.2025</b></p>
          <!-- <div class="badge badge-light-info d-inline">
            Ofisten Çalışan Fullstack Developer
            </div> -->
          <!--begin::Permissions-->
          <!--               <h2 contenteditable spellcheck="false">Ofisten Çalışan Fullstack Developer</h2> -->
          <div class="d-flex flex-column text-gray-600">
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Minimum 4 yıl iş
              tecrübesi
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> İlgili mühendislik
              bölümlerinden mezun
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span>.NET Core'a hakim
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Projede eksikleri
              saptayıp ona göre zaman planı çıkartabilecek
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Database yapıları
              hakkında bilgi sahibi
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span>
              <em>3 adet daha..</em>
            </div>
          </div>
          <!--end::Permissions-->
          <div class="separator my-4"></div>

          <div class="d-flex align-items-center mb-5 mt-6">
            <h5 class="fw-bold me-2">Mülakat:</h5>
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-control form-select-sm form-control-solid
                fw-bolder
                w-175px
                mb-1
              "
            >
              <option value="Active" selected>Teknik Mülakat</option>
              <option value="Approved">İngilizce Mülakat</option>
              <option value="Declined">Star Mülakat</option>
            </select>
            <!--end::Select-->
          </div>
          <h5>Teknik Mülakat</h5>
          <div class="fw-bold fs-7 text-gray-600">
            Bu mülakat, yazılım geliştirme pozisyonu için adayların teknik
            becerilerini ve deneyimlerini değerlendirir.
          </div>
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer flex-wrap pt-0">
          <router-link to="/interview/invite-candidate">
            <a class="btn btn-sm btn-primary btn-active-primary my-1 w-100">
              Aday Davet Et
            </a>
          </router-link>
        </div>

        <!--end::Card footer-->
      </div>
      <!--end::Card-->

      <!--begin::Modal - Update role-->
      <div
        class="modal fade"
        id="kt_modal_update_role"
        tabindex="-1"
        aria-hidden="true"
      >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-750px">
          <!--begin::Modal content-->
          <div class="modal-content">
            <!--begin::Modal header-->
            <div class="modal-header">
              <!--begin::Modal title-->
              <h2 class="fw-bold">Mülakatı Güncelle</h2>
              <!--end::Modal title-->

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-roles-modal-action="close"
              >
                <i class="ki-duotone ki-cross fs-1"
                  ><span class="path1"></span><span class="path2"></span
                ></i>
              </div>
              <!--end::Close-->
            </div>
            <!--end::Modal header-->

            <!--begin::Modal body-->
            <div class="modal-body scroll-y mx-5">
              <!--begin::Tabs-->
              <ul
                class="
                  nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x
                  border-0
                  fs-4
                  fw-semibold
                  mb-8
                "
                role="tablist"
              >
                <!-- Tab 1: Mülakat Detayları -->
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link text-active-primary pb-4 active"
                    data-bs-toggle="tab"
                    href="#kt_modal_tab_details"
                    role="tab"
                    aria-selected="true"
                  >
                    Mülakat Detayları
                  </a>
                </li>

                <!-- Tab 2: Mülakat Soruları -->
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link text-active-primary pb-4"
                    data-bs-toggle="tab"
                    href="#kt_modal_tab_questions"
                    role="tab"
                    aria-selected="false"
                  >
                    Mülakat Soruları
                  </a>
                </li>
              </ul>
              <!--end::Tabs-->

              <div class="tab-content">
                <!--begin::Tab Pane 1-->
                <div
                  class="tab-pane fade show active"
                  id="kt_modal_tab_details"
                  role="tabpanel"
                >
                  <!--begin::Form: Mülakat Detayları-->
                  <form id="kt_modal_update_role_form_details" class="form">
                    <div class="fv-row mb-10">
                      <label class="fs-5 fw-bold form-label mb-2">
                        <span class="required">Mülakat Başlığı</span>
                      </label>
                      <input
                        class="form-control form-control-solid"
                        placeholder="Mülakat başlığı giriniz..."
                        name="role_name"
                        value="Teknik Mülakat"
                      />
                    </div>

                    <div class="fv-row mb-10">
                      <label class="fs-5 fw-bold form-label mb-2"
                        >Mülakat Açıklaması</label
                      >
                      <vue-editor
                        v-model="positionDesc"
                        class="form-control form-control-solid"
                        style="padding: 0px"
                      ></vue-editor>
                    </div>
                  </form>
                  <!--end::Form-->
                </div>
                <!--end::Tab Pane 1-->

                <!--begin::Tab Pane 2-->
                <div
                  class="tab-pane fade"
                  id="kt_modal_tab_questions"
                  role="tabpanel"
                >
                  <!--begin::Form: Mülakat Soruları-->
                  <form id="kt_modal_update_role_form_questions" class="form">
                    <div class="fv-row mb-5">
                      <label class="fs-5 fw-bold form-label mb-2"
                        >Yeni Soru</label
                      >
                      <div class="d-flex align-items-center mb-3">
                        <textarea
                          class="
                            form-control form-control-lg form-control-solid
                            me-3
                          "
                          v-model="newQuestion.text"
                          @keyup.enter="addQuestion"
                          placeholder="Sorunuzu yazın ve Enter'a basın"
                          style="flex-grow: 1"
                        ></textarea>

                        <select
                          v-model="newQuestion.type"
                          class="form-select form-select-lg"
                          aria-label="Soru Türü"
                          style="width: 200px"
                        >
                          <option value="open">Açık Uçlu</option>
                          <option value="single">Tek Seçenekli</option>
                          <option value="multiple">Çok Seçenekli</option>
                        </select>

                        <button
                          @click.prevent="addQuestion"
                          class="btn btn-primary ms-2"
                          type="button"
                        >
                          Ekle
                        </button>
                      </div>
                      <div
                        v-if="
                          newQuestion.type === 'single' ||
                          newQuestion.type === 'multiple'
                        "
                        class="mb-3"
                      >
                        <input
                          type="text"
                          v-model="newOptionText"
                          @keyup.enter="addOption"
                          class="form-control form-control-sm me-3"
                          placeholder="Opsiyon eklemek için yazın ve Enter'a basın"
                          v-on:keydown.enter.prevent
                        />
                        <!-- Eklenen Opsiyonları Gösterme -->
                        <ul class="list-group mt-2">
                          <li
                            v-for="(option, index) in newQuestion.options"
                            :key="index"
                            class="
                              list-group-item
                              d-flex
                              justify-content-between
                              align-items-center
                            "
                          >
                            {{ option }}
                            <button
                              type="button"
                              @click="removeOption(index)"
                              class="btn btn-sm btn-danger"
                            >
                              Sil
                            </button>
                          </li>
                        </ul>
                      </div>

                    </div>

                    <div class="fv-row mb-5">
                      <label class="fs-5 fw-bold form-label mb-2"
                        >Mevcut Sorular</label
                      >
                      <div v-if="questions.length > 0">
                        <div
                          v-for="(question, qIndex) in questions"
                          :key="qIndex"
                          class="mb-5 border p-3 rounded"
                        >
 <label class="form-label">Soru {{ qIndex + 1 }}</label>
                        <input
                          type="text"
                          v-model="question.text"
                          class="form-control form-control-solid mb-3"
                          placeholder="Soruyu düzenleyin"
                        />

                        <!-- Soru Tipi -->
                        <label class="form-label">Soru Türü</label>
                        <select
                          v-model="question.type"
                          class="form-select mb-3"
                          @change="clearOptions(qIndex)"
                        >
                          <option value="open">Açık Uçlu</option>
                          <option value="single">Tek Seçenekli</option>
                          <option value="multiple">Çok Seçenekli</option>
                        </select>

                        <!-- Opsiyonlar (Tek veya Çok Seçenekli Sorular İçin) -->
                        <div
                          v-if="
                            question.type === 'single' ||
                            question.type === 'multiple'
                          "
                          class="mb-3"
                        >
                          <label class="form-label">Opsiyonlar</label>

                          <div
                            v-for="(option, oIndex) in question.options"
                            :key="oIndex"
                            class="d-flex align-items-center mb-2"
                          >
                            <input
                              type="text"
                              v-model="question.options[oIndex]"
                              class="form-control form-control-sm me-3"
                            />
                            <button
                              type="button"
                              @click="removeExistingOption(qIndex, oIndex)"
                              class="btn btn-sm btn-danger"
                            >
                              Sil
                            </button>
                          </div>

                          <!-- Yeni Opsiyon Ekle -->
                          <div class="d-flex align-items-center">
                            <input
                              type="text"
                              v-model="newOptionText"
                              class="form-control form-control-sm me-3"
                              placeholder="Yeni opsiyon ekle"
                              @keyup.enter="addOptionToQuestion(qIndex)"
                            />
                            <button
                              @click.prevent="addOptionToQuestion(qIndex)"
                              class="btn btn-secondary btn-sm"
                            >
                              Ekle
                            </button>
                          </div>
                        </div>

                        <!-- Soru Sil Butonu -->
                        <button
                          type="button"
                          @click="removeQuestion(qIndex)"
                          class="btn btn-danger btn-sm mt-3"
                        >
                          Soru Sil
                        </button>
                      </div>
                    </div>
                    <div v-else class="text-gray-400 fw-bold fs-7">
                      Henüz soru eklenmedi.
                    </div>
                    </div>
                  </form>
                  <!--end::Form-->
                </div>
                <!--end::Tab Pane 2-->
              </div>

              <!--begin::Actions-->
              <div class="text-center pt-15">
                <button
                  type="reset"
                  class="btn btn-light me-3"
                  data-bs-dismiss="modal"
                >
                  Vazgeç
                </button>

                <button
                  type="submit"
                  class="btn btn-primary"
                  data-kt-roles-modal-action="submit"
                >
                  <span class="indicator-label"> Güncelle </span>
                </button>
              </div>
              <!--end::Actions-->
            </div>

            <!--end::Modal body-->
          </div>
          <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
      </div>
      <!--end::Modal - Update role-->
    </div>
    <!--end::Sidebar-->

    <!--begin::Content-->
    <div class="flex-lg-row-fluid ms-lg-10">
      <!--begin::Card-->
      <div class="card card-flush mb-6 mb-xl-9">
        <!--begin::Card header-->
        <div class="card-header pt-5">
          <!--begin::Card title-->
          <div class="card-title">
            <h2 class="d-flex align-items-center">
              İş İlanına Başvuran Adaylar<span class="text-gray-600 fs-6 ms-1"
                >(4)</span
              >
            </h2>
          </div>
          <!--end::Card title-->

          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Search-->
            <div
              class="d-flex align-items-center position-relative my-1"
              data-kt-view-roles-table-toolbar="base"
            >
              <i class="ki-duotone ki-magnifier fs-1 position-absolute ms-6"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <input
                type="text"
                data-kt-roles-table-filter="search"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Aday ara"
              />
            </div>
            <!--end::Search-->

            <!--begin::Group actions-->
            <div
              class="d-flex justify-content-end align-items-center d-none"
              data-kt-view-roles-table-toolbar="selected"
            >
              <div class="fw-bold me-5">
                <span
                  class="me-2"
                  data-kt-view-roles-table-select="selected_count"
                ></span>
                Selected
              </div>

              <button
                type="button"
                class="btn btn-danger"
                data-kt-view-roles-table-select="delete_selected"
              >
                Delete Selected
              </button>
            </div>
            <!--end::Group actions-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Table-->
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="table-responsive">
              <table
                class="
                  table
                  align-middle
                  table-row-dashed
                  fs-6
                  gy-5
                  mb-0
                  dataTable
                "
                id="kt_roles_view_table"
                style="width: 100%"
              >
                <thead>
                  <tr
                    class="
                      text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                  >
                    <th
                      class="w-10px pe-2 dt-orderable-none"
                      data-dt-column="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="
                    "
                    >
                      <span class="dt-column-title">
                        <div
                          class="
                            form-check
                            form-check-sm
                            form-check-custom
                            form-check-solid
                            me-3
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            data-kt-check="true"
                            data-kt-check-target="#kt_roles_view_table .form-check-input"
                            value="1"
                          />
                        </div> </span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th width="35%">
                      <span class="dt-column-title" role="button"
                        >Aday Bilgileri</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th width="20%">
                      <span class="dt-column-title" role="button"
                        >Son İşlem Tarihi</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th width="25%">
                      <span class="dt-column-title" role="button">Durum</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th width="20%">
                      <span class="dt-column-title">Mülakat İşlemleri</span
                      ><span class="dt-column-order"></span>
                    </th>
                  </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                  <tr>
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                        <a href="#">
                          <div
                            class="
                              symbol-label
                              fs-3
                              bg-light-danger
                              text-danger
                            "
                          >
                            B
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <router-link to="/interview/candidate-detail">
                      <div class="d-flex flex-column">
                        <a
                          href="#"
                          class="text-gray-800 text-hover-primary mb-1"
                          >Buse Çallı</a
                        >
                        <span class="text-gray-700">busecalli25@gmail.com</span>
                      </div>
                      </router-link>
                      <!--begin::User details-->
                    </td>
                    <td data-order="2024-09-22T20:43:00+03:00">
                      22 Mayıs 2024, 8:43
                    </td>
                    <td class="text-center">
                      <span class="badge badge-light-warning"
                        >Davet Gönderildi</span
                      >
                    </td>
                    <td class="text-end">
                      <a
                        class="
                          btn btn-sm btn-danger btn-active-primary
                          my-1
                          w-100
                        "
                      >
                        Daveti İptal Et
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                        <a href="#">
                          <div
                            class="
                              symbol-label
                              fs-3
                              bg-light-danger
                              text-danger
                            "
                          >
                            M
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <router-link to="/interview/candidate-detail">
                      <div class="d-flex flex-column">
                        <a
                          href="#"
                          class="text-gray-800 text-hover-primary mb-1"
                          >Melodi Acar</a
                        >
                        <span class="text-gray-700">melody@gmail.com</span>
                      </div>
                      </router-link>
                      <!--begin::User details-->
                    </td>
                    <td data-order="2024-04-15T20:43:00+03:00">
                      15 Nisan 2024, 18:43
                    </td>
                    <td class="text-center">
                      <span class="badge badge-light-danger"
                        >Davet Bekliyor</span
                      >
                    </td>

                    <td class="text-end">
                      <a class="btn btn-sm btn-success my-1 w-100">
                        <router-link to="/interview/invite-candidate" style="color:#fff">
                        Davet Gönder
                      </router-link>
                      </a>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                        <a href="#">
                          <div
                            class="
                              symbol-label
                              fs-3
                              bg-light-primary
                              text-primary
                            "
                          >
                            B
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <router-link to="/interview/candidate-detail">
                      <div class="d-flex flex-column">
                        <a
                          href="#"
                          class="text-gray-800 text-hover-primary mb-1"
                          >Beytullah Perşembe</a
                        >
                        <span class="text-gray-700">beytu@kt.com</span>
                      </div>
                      </router-link>
                      <!--begin::User details-->
                    </td>
                    <td data-order="2024-04-15T11:30:00+03:00">
                      15 Nisan 2024, 11:30
                    </td>
                    <td class="text-center">
                      <span class="badge badge-light-success">Tamamlandı</span>
                    </td>
                    <td class="text-end">
                      <a class="btn btn-sm btn-primary my-1 w-100"> İncele </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                        <a href="#">
                          <div
                            class="
                              symbol-label
                              fs-3
                              bg-light-primary
                              text-primary
                            "
                          >
                            S
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <router-link to="/interview/candidate-detail">
                      <div class="d-flex flex-column">
                        <a
                          href="#"
                          class="text-gray-800 text-hover-primary mb-1"
                          >Sinan Kılıç</a
                        >
                        <span class="text-gray-700">sinan@gmail.com</span>
                      </div>
                      </router-link>
                      <!--begin::User details-->
                    </td>
                    <td data-order="2024-06-24T17:20:00+03:00">
                      24 Mart 2024, 15:20
                    </td>
                    <td class="text-center">
                      <span class="badge badge-light-success">Tamamlandı</span>
                    </td>
                    <td class="text-end">
                      <a class="btn btn-sm btn-primary my-1 w-100"> İncele </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
            <div id="" class="row">
              <div
                id=""
                class="
                  col-sm-12 col-md-5
                  d-flex
                  align-items-center
                  justify-content-center justify-content-md-start
                  dt-toolbar
                "
              ></div>
              <div
                id=""
                class="
                  col-sm-12 col-md-7
                  d-flex
                  align-items-center
                  justify-content-center justify-content-md-end
                "
              >
                <div class="dt-paging paging_simple_numbers">
                  <nav aria-label="pagination">
                    <ul class="pagination">
                      <li class="dt-paging-button page-item disabled">
                        <button
                          class="page-link previous"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          aria-disabled="true"
                          aria-label="Previous"
                          data-dt-idx="previous"
                          tabindex="-1"
                        >
                          <i class="previous"></i>
                        </button>
                      </li>
                      <li class="dt-paging-button page-item active">
                        <button
                          class="page-link"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          aria-current="page"
                          data-dt-idx="0"
                        >
                          1
                        </button>
                      </li>
                      <li class="dt-paging-button page-item">
                        <button
                          class="page-link"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          data-dt-idx="1"
                        >
                          2
                        </button>
                      </li>
                      <li class="dt-paging-button page-item">
                        <button
                          class="page-link"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          data-dt-idx="2"
                        >
                          3
                        </button>
                      </li>
                      <li class="dt-paging-button page-item">
                        <button
                          class="page-link next"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          aria-label="Next"
                          data-dt-idx="next"
                        >
                          <i class="next"></i>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";

export default defineComponent({
  name: "NewInterviewDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const positionDesc = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate
        ? new Date(oldDate).toISOString().split("T")[0]
        : "";
      selectedInterviewId.value = id;
    };

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([
      {
        text: "React ile kaç yıl tecrübeniz var? ",
        type: "open",
      },
      {
        text: "Frontend projesi yapacak olsanız opsiyonel olarak hangi kütüphaneyi kullanmak isterdiniz?  ",
        type: "single",
        options: ["React", "Vuejs", "Angular"],
      },
    ]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    const addOptionToQuestion = (qIndex) => {
      if (newOptionText.value.trim() !== "") {
        questions.value[qIndex].options.push(newOptionText.value);
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeExistingOption = (qIndex, oIndex) => {
      questions.value[qIndex].options.splice(oIndex, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const clearOptions = (qIndex) => {
      questions.value[qIndex].options = [];
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {
      url,
      form,
      newEndDate,
      changeDateRef,
      selectInterview,
      isLoading,
      positionDesc,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      addOptionToQuestion,
      removeOption,
      removeExistingOption,
      removeQuestion,
      clearOptions,
    };
  },
});
</script>
