
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";

export default defineComponent({
  name: "NewInterviewDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const positionDesc = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate
        ? new Date(oldDate).toISOString().split("T")[0]
        : "";
      selectedInterviewId.value = id;
    };

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([
      {
        text: "React ile kaç yıl tecrübeniz var? ",
        type: "open",
      },
      {
        text: "Frontend projesi yapacak olsanız opsiyonel olarak hangi kütüphaneyi kullanmak isterdiniz?  ",
        type: "single",
        options: ["React", "Vuejs", "Angular"],
      },
    ]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    const addOptionToQuestion = (qIndex) => {
      if (newOptionText.value.trim() !== "") {
        questions.value[qIndex].options.push(newOptionText.value);
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeExistingOption = (qIndex, oIndex) => {
      questions.value[qIndex].options.splice(oIndex, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const clearOptions = (qIndex) => {
      questions.value[qIndex].options = [];
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {
      url,
      form,
      newEndDate,
      changeDateRef,
      selectInterview,
      isLoading,
      positionDesc,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      addOptionToQuestion,
      removeOption,
      removeExistingOption,
      removeQuestion,
      clearOptions,
    };
  },
});
